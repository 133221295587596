.shortAboutSection {
  position: relative;
  width: 100vw;
  height: auto;
  top: 100px;
}

.about {
  margin: auto;
  width: 70%;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.where,
.whatWeUse,
.quality {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 20px;
  height: 400px;
  margin: 10px;
  color: rgb(211, 211, 211);
  background: rgb(83, 83, 83);
  transition: all 0.3s linear;
}

.where {
  animation-name: aboutSlide;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  transform: translateX(-200vw);
  animation-delay: 0.5s;
  animation-play-state: paused;
}
.whatWeUse {
  animation-name: aboutSlide;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  transform: translateX(-200vw);
  animation-delay: 0.3s;
  animation-play-state: paused;
}
.quality {
  animation-name: aboutSlide;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  transform: translateX(-200vw);
  animation-delay: 0.1s;
  animation-play-state: paused;
}
.text {
  color: rgb(211, 211, 211);
  font-size: 17px;
  width: calc(70vw + 40px);
  margin: auto;
  margin-top: 10px;
  text-align: center;
  margin-bottom: 50px;
  animation-name: aboutSlide;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  transform: translateX(-200vw);
  animation-delay: 0.7s;
  animation-play-state: paused;
  background: rgba(0, 0, 0, 0.267);
  padding: 40px;
}
.title {
  color: rgb(211, 211, 211);
  font-size: 40px;
  margin-left: 15vw;
}

.qualityPic {
  background: url("../../assets/about/pexels-ivan-samkov-4491846.jpeg");
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
}

.wherePic {
  background: url("../../assets/about/pexels-anthony-beck-4278035.jpeg");
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
}

.whatWeUsePic {
  background: url("../../assets/about/Kiln-dried-wood.jpeg");
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
}

@keyframes aboutSlide {
  to {
    transform: translateX(0);
  }
}

.whereText,
.whatWeUseText,
.qualityText {
  margin-top: 50px;
  width: 20vw;
}

@media (max-width: 1000px) {
  .about {
    flex-direction: column;
    width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
  }

  .whereText,
  .whatWeUseText,
  .qualityText {
    width: 90vw;
  }
  .where,
  .whatWeUse,
  .quality {
    transform: translateX(0);
    animation-name: none;
    margin-left: 0;
    margin-right: 0;
  }
}
