.background {
  /* position: relative; */
  /* margin-top: 100px; */
  background: url("../../../assets/headerBackground/pexels-cleyder-duque-3637789.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100vw;
  /* top: 100px; */
  height: calc(100vh - 100px - 100px);
  z-index: -100;
  animation-name: backgroundSize;
  animation-duration: 30s;
  /* animation-delay: 1s; */
  animation-iteration-count: infinite;

  /* height: 60%; */
  /* transition: all 0.2s linear; */
}
@keyframes backgroundSize {
  /* from {
    background-size: 100%;
  }
  to {
    background-size: 120%;
  } */
  0% {
    background-size: 100%;
  }
  50% {
    background-size: 120%;
  }
  100% {
    background-size: 100%;
  }
}

.dimm {
  /* position: relative; */
  width: 100vw;
  height: calc(100vh - 100px - 100px);
  background: rgba(94, 94, 94, 0.589);
}

.nameBox {
  position: relative;
  top: -55vh;
  width: 100%;
  /* background: red; */
  display: flex;
  flex-direction: column;
  text-align: center;
}
.name {
  font-family: "Lora", serif;

  /* font-weight: bold; */
  color: rgb(214, 112, 68);
  font-size: 50px;
  transform: translateX(-200%);
  animation-name: nameSlide;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  /* animation-delay: 1s; */
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes nameSlide {
  to {
    transform: translateX(0);
  }
}

.companyText {
  font-family: "Lora", serif;

  color: white;
  font-size: 20px;
  transform: translateX(200%);
  animation-name: nameSlide;
  /* animation-delay: 1s; */
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
@media (max-width: 1300px) {
  .background {
    background-size: cover;
    animation-name: backgroundPosition;
  }
  @keyframes backgroundPosition {
    0% {
      background-position: center;
    }
    50% {
      background-position: top;
    }
    100% {
      background-position: center;
    }
  }
}

@media (max-width: 668px) {
  .background {
    position: relative;
    top: 100px;
    /* margin-top: 100px; */
    background-position: top;
    background-size: cover;
    height: 50vh;
    /* transition: all 0.2s linear; */
  }
  @keyframes backgroundPosition {
    0% {
      background-position: center;
    }
    33% {
      background-position: left;
    }
    66% {
      background-size: right;
    }

    100% {
      background-position: center;
    }
  }
  .dimm {
    /* position: relative; */
    width: 100vw;
    height: 50vh;
    /* background: rgba(94, 94, 94, 0.589); */
    /* background: none; */
  }
  .nameBox {
    top: -50%;
  }
  .name {
    font-size: 25px;
  }
  .companyText {
    font-size: 15px;
  }
}
