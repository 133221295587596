.hamburgerBox {
  position: absolute;
  right: 30px;
  top: 40px;
  height: 14px;
  width: 30px;
  /* background: rgb(26, 26, 26); */
  background: rgba(0, 0, 0, 0.74);

  padding-top: 10px;
  z-index: 50000;
}

.hamburger {
  position: relative;

  background: white;
  height: 3px;
  width: 30px;
  transition: all 0.2s linear;
  z-index: 50000;
}
.hamburger:after {
  content: "";
  position: absolute;
  height: 3px;
  width: 30px;
  background: white;
  margin-top: 8px;
  transition: all 0.2s linear;
  z-index: 50000;
}
.hamburger::before {
  content: "";
  position: absolute;
  height: 3px;
  width: 30px;
  background: white;
  margin-top: -8px;
  z-index: 50000;
}

.hamburgerOpen {
  position: relative;
  background: white;
  height: 3px;
  width: 30px;
  transform: rotate(45deg);
  transition: all 0.2s linear;
  z-index: 50000;
}
.hamburgerOpen:after {
  content: "";
  position: absolute;
  height: 3px;
  top: -7px;
  right: 0px;
  width: 30px;
  background: white;
  margin-top: 8px;
  transform: rotate(-90deg);
  transition: all 0.2s linear;
  z-index: 50000;
}
.hamburgerOpen:before {
  content: "";
  position: absolute;
  height: 3px;
  top: 8px;
  width: 30px;
  background: white;
  margin-top: -8px;
  transition: all 0.2s linear;
  z-index: 50000;
}

@media (min-width: 1100px) {
  .hamburgerBox {
    display: none;
  }
  .hamburger {
    display: none;
  }
}
