.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.622);
  /* background: red; */
  z-index: 49000;
}
.close {
  position: fixed;
  right: 0;
  top: 0;
  padding: 5px;
  margin: 30px;
  width: fit-content;
  cursor: pointer;
  color: white;
  border-radius: 10px;
  background: black;
  border: 1px solid white;
  z-index: 51000;
}

.selectedPicture {
  position: fixed;
  margin: auto;
  width: 90vw;
  top: 5vh;
  left: 5vw;
  background: rgb(0, 0, 0);
  /* border-radius: 10px; */
  box-shadow: 0 0 10px black;
  height: 90vh;
  z-index: 50000;
}

.pic {
  width: 90vw;
  height: 65vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 5px;
  transition: all 0.2s linear;
}
.pictureHorizontalList {
  position: absolute;
  top: 65vh;
  left: 0;
  width: 90vw;
  height: 25vh;
  /* border-radius: 10px; */
  /* box-shadow: 0 0 10px wheat; */

  background: rgb(30, 30, 30);
  border-top: 2px solid rgb(211, 147, 28);
}
.container {
  /* background: white; */
  width: 100%;
  /* display: flex;
  flex-direction: row; */
  overflow-y: hidden;
  overflow-x: scroll;
  white-space: nowrap;
}
.pictureHorizontalListEl {
  display: inline-block;
  height: 20vh;
  width: 200px;
  margin: 10px;
  margin-top: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
}

@media (max-width: 800px) {
  .selectedPicture {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }
  .pic {
    width: 100vw;
    height: 75vh;
  }
  .pictureHorizontalList {
    width: 100vw;
    height: 25vh;
    top: 75vh;
  }
}

@media (max-width: 450px) {
  .pictureHorizontalListEl {
    width: 120px;
    height: 120px;
  }
  .pictureHorizontalList {
    top: 60vh;
  }
}
