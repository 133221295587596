.itemCard {
  /* background: grey; */
  /* width: 100%; */
  height: 600px;
  width: 70vw;
  /* border-left: 1px solid gold; */
  /* background: red; */
  /* padding: 10px; */
  /* margin: 10px; */
  transition: all 0.2s linear;
  display: flex;
  flex-direction: row;
  /* border-radius: 10px; */
  transform: translateX(-200vw);
  animation-name: offerSlide;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  margin-bottom: 200px;
  animation-play-state: paused;

  /* text-align: center; */
  /* align-items: center; */
  /* justify-content: end; */
}

@keyframes offerSlide {
  to {
    transform: translateX(0);
  }
}

/* .itemCard:hover {
  box-shadow: 0 0 10px black;
}
.itemCard:hover .header {
  font-size: 25px;
  padding: 0;
} */
/* .itemCard:hover .info {
  font-size: 14px;
  text-align: justify;
  padding: 0;
}
.itemCard:hover .pic {
  background-size: 105%;
  padding: 0;
  height: 70%;
}*/

.number {
  position: absolute;
  height: calc(100%);
  /* border-radius: 20px 20px 0 0; */
  background: rgba(102, 102, 102, 0.425);
  padding-left: 10px;
  padding-right: 10px;
  top: -80px;
  left: 60vw;
  color: rgb(211, 211, 211);
  font-weight: 900;
  box-shadow: 0 0 20px black;
  font-size: 70px;
  font-family: "Lora", serif;
  transition: all 0.3s linear;
}
/* .number:hover {
  top: -80px;
  height: calc(100% + 80px);
} */

/* .line {
  position: absolute;
  top: 40px;
  left: calc(15% + 40px);
  height: 5px;
  width: 700px;
  background: rgb(112, 112, 112);
} */

.line {
  position: absolute;
  top: 40px;
  left: calc(15% - 200px);
  height: 0;
  width: 0;
  border-left: 700px solid gold;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  animation-name: lineColor;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}
@keyframes lineColor {
  0% {
    border-left: 700px solid gold;
  }
  50% {
    border-left: 700px solid rgb(82, 67, 20);
  }
  100% {
    border-left: 700px solid gold;
  }
}

.lineBottom {
  position: absolute;
  top: 550px;
  left: calc(-5%);
  height: 3px;
  width: 350px;
  background: gold;
  animation-name: lineBottomColor;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}
@keyframes lineBottomColor {
  0% {
    background: rgb(82, 67, 20);
  }
  50% {
    background: gold;
  }
  100% {
    background: rgb(82, 67, 20);
  }
}

.pic {
  position: absolute;
  top: 0;
  left: 35%;
  width: 70%;
  height: 600px;
  /* border: 1px solid red; */
  /* text-align: center; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 0px;
  /* border-radius: 10px; */
  /* border: 1px solid black; */
  /* transition: all 2s linear; */
  box-shadow: 0 0 10px black;
  z-index: -100;
}

.smallPicBox {
  background: inherit;
  /* border: 1px solid red; */
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 300px;
  /* width: 300px; */
  left: calc(0px - 200px);
}

.smallPic {
  width: 150px;
  height: 150px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 10px;
}
@media (max-width: 1300px) {
  .smallPicBox {
    top: 350px;
    left: calc(0px - 150px);
  }
  .smallPic {
    width: 120px;
    height: 120px;
  }
}
@media (max-width: 1100px) {
  .pic {
    left: 40%;
  }
}
.header {
  position: absolute;
  top: -20px;
  left: -15vw;
  /* background: rgba(153, 153, 153, 0.76); */
  /* box-shadow: 0 0 20px black; */
  width: 400px;
  text-align: center;
  border-radius: 20px;
  color: rgb(211, 211, 211);
  font-weight: bold;
  font-size: 30px;
  padding: 0 5px;
  margin-top: 10px;
  margin-bottom: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: "Lora", serif;
  transition: all 0.2s linear;
}
.info {
  position: relative;
  top: 100px;
  height: 350px;
  left: -15vw;
  width: 30vw;
  color: rgb(211, 211, 211);
  padding: 0 5px;
  font-size: 20px;
  text-align: left;
  /* border-radius: 10px; */
  padding: 20px;

  font-family: "Lora", serif;
  transition: all 0.2s linear;

  /* padding-bottom: 10px; */
}

@media (max-width: 1000px) {
  .itemCard {
    animation-duration: none;
    transform: translateX(0);
    height: 100vh;
    width: 100vw;
    margin-left: 0;
    margin-right: 0;
    margin-top: 100px;
  }
  .number {
    left: 10%;
  }
  .header {
    top: -90px;
    left: -100px;
    font-size: 20px;
  }
  .line {
    top: -30px;
    left: 0;
    border-left: 300px solid gold;
    animation-name: lineColor;
  }
  @keyframes lineColor {
    0% {
      border-left: 300px solid gold;
    }
    50% {
      border-left: 300px solid rgb(82, 67, 20);
    }
    100% {
      border-left: 300px solid gold;
    }
  }
  .pic {
    width: 100vw;
    height: 60vh;
    left: 0;
  }

  .smallPicBox {
    width: 100vw;
    position: absolute;
    /* left: 0; */
    left: 0;
    margin: auto;
    /* border: 1px solid red; */
    align-items: center;
    text-align: center;
    justify-content: center;
    top: 60vh;
  }
  .smallPic {
    width: calc(50vw - 20px);
    height: 200px;
  }
  .number {
    left: 70vw;
    height: 60vh;
  }
  .info {
    /* position: relative; */

    text-align: left;
    /* padding-left: 5px; */
    width: 100vw;
    bottom: 0;
    top: calc(60vh + 200px);
    padding-top: 5vh;
    left: 0;
    font-size: 20px;
  }
  .lineBottom {
    width: 100vw;
    top: calc(60vh + 200px + 20px + 20px);
  }
}

@media (max-width: 600px) {
  .smallPic {
    height: 100px;
  }
  .lineBottom {
    top: calc(60vh + 100px + 20px + 20px);
  }
  .info {
    top: calc(60vh + 100px);
  }
}
