.rightSlide {
  display: none;
  position: absolute;
  background: rgba(0, 0, 0, 0.74);
  width: 30vw;
  top: 0;
  right: 0;
  color: rgb(211, 211, 211);
  z-index: 900;
  height: 100vh;
  transition: all 0.3s linear;
}
.links {
  margin-left: 20%;
  text-align: left;
  margin-top: 100px;
}
.btn {
  margin-top: 20px;
  font-size: 25px;
  cursor: pointer;
}

.btn:hover {
  color: rgb(211, 147, 28);
}

.close {
  /* display: none; */
  transform: translateX(50vw);
}

.open {
  /* display: block; */
  transform: translateX(0);
}

@media (max-width: 1100px) {
  .rightSlide {
    display: block;
  }
}
@media (max-width: 850px) {
  .rightSlide {
    width: 40vw;
  }
}
@media (max-width: 600px) {
  .rightSlide {
    width: 50vw;
  }
}

@media (max-width: 500px) {
  .btn {
    margin-top: 20px;
    font-size: 15px;
    cursor: pointer;
  }
}
