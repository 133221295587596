.MainSection {
  position: relative;
  /* top: calc(100vh + 360px); */
  /* width: 70%; */
  width: 100vw;
  /* background: grey; */

  /* margin-left: 15%; */
  display: grid;
  /* display: none; */
  margin-top: 200px;
  grid-template-columns: 1fr;
  row-gap: 30px;
  column-gap: 30px;
  /* padding-left: 15%; */
  justify-items: center;
}
.offerTitle {
  position: relative;
  top: 90px;
  /* margin-top: 100px; */
  color: rgb(211, 211, 211);

  /* background: red; */
  /* width: calc(100vw - 20%); */
  font-size: 40px;
  /* font-weight: 800; */
  font-family: "Lora", serif;
  /* padding-top: 100px; */
  margin-left: 15vw;
  /* border: 1px solid red; */
  /* padding-left: 20%; */
}

@media (max-width: 1200px) {
  .MainSection {
    width: 100vw;
    padding-left: 0;
    column-gap: 5px;
  }
}
@media (max-width: 830px) {
  /* .offerTitle {
    top: 200px;
  } */
  .MainSection {
    width: 100vw;
    /* column-gap: 5px; */
    grid-template-columns: 1fr;
    row-gap: 10px;
  }
}
