.gridWithAllPictures {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.pic {
  width: 200px;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* border: 1px solid red; */
  border-radius: 5px;
  margin: 10px;
  transition: all 0.3s linear;
  box-shadow: 0 0 5px white;
  cursor: pointer;
}
.pic:hover {
  width: 220px;
  height: 220px;
  margin: 0;
}

@media (max-width: 1250px) {
  .gridWithAllPictures {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 940px) {
  .pic {
    height: 180px;
    width: 180px;
  }
  .pic:hover {
    height: 190px;
    width: 190px;
  }
}
@media (max-width: 600px) {
  .pic {
    height: 140px;
    width: 140px;
  }
  .pic:hover {
    height: 150px;
    width: 150px;
  }
}

@media (max-width: 480px) {
  /* .gridWithAllPictures {
    grid-template-columns: repeat(2, 1fr);
  } */
  .pic {
    width: 120px;
    height: 120px;
    transition: all 0.2s linear;
  }
  .pic:hover {
    width: 130px;
    height: 130px;
  }
}
@media (max-width: 430px) {
  .pic {
    width: 110px;
    height: 110px;
  }
  .pic:hover {
    width: 115px;
    height: 115px;
  }
}
@media (max-width: 390px) {
  /* .gridWithAllPictures {
    grid-template-columns: repeat(3, 1fr);
  } */
  .pic {
    width: 100px;
    height: 100px;
    margin: 5px;
    transition: all 0.2s linear;
  }
  .pic:hover {
    width: 110px;
    height: 110px;
  }
}
@media (max-width: 350px) {
  .pic {
    width: 85px;
    height: 85px;
    margin: 5px;
    transition: all 0.2s linear;
  }
  .pic:hover {
    width: 85px;
    height: 85px;
    margin: 5px;
  }
}
