.footer {
  /* display: none; */
  /* position: static; */
  /* bottom: auto; */
  z-index: 400;
  width: 100vw;
  /* height: 400px; */
  background: rgb(65, 65, 65);
  /* margin-top: 150px; */
  display: flex;
  /* border: 1px solid rgb(255, 230, 0); */
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: left;
}

.links {
  /* margin: auto; */
  width: auto;
  margin-left: 200px;
  height: 200px;
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: space-evenly;
  transition: all 0.2s linear;
}
.links a {
  outline: none;
  text-decoration: none;
  color: rgb(211, 211, 211);
  transition: all 0.2s linear;
}
.links a:hover {
  color: rgb(211, 147, 28);
}

.about,
.phone,
.mail,
.adress {
  cursor: pointer;
  color: rgb(211, 211, 211);
  transition: all 0.2s linear;
}
.about:hover,
.phone:hover,
.mail:hover {
  color: rgb(211, 147, 28);
}

.logo {
  /* border: 1px solid red; */
  width: 400px;
  height: 400px;
  margin-left: 10vw;
  /* margin-left: 50px; */
  /* border: 1px solid red; */

  background: url("../../assets/logo/logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 1200px) {
  .links {
    flex-direction: column;
    margin: auto;
    width: 100vw;
  }
  .footer {
    flex-direction: column;
  }
  .logo {
    margin-left: 0;
  }
}
@media (max-width: 700px) {
  .footer {
    height: calc(100vh - 100px);
  }
  .logo {
    width: 100vw;
  }
}
