.howWeBuild {
  width: 90vw;
  /* background: red; */
  /* height: 100vh; */
  /* margin: auto; */
  margin-left: 5vw;
  margin-bottom: 10vh;
  transform: translateX(-150vw);
  animation-name: slide;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-play-state: paused;
}
@keyframes slide {
  to {
    transform: translateX(0);
  }
}
.title {
  margin-top: 20px;
  position: relative;
  /* top: -5vh; */
  margin-bottom: 5vh;
  font-size: 40px;
  color: rgb(211, 211, 211);
  padding-left: 15vw;
  /* font-weight: 800; */
}
.mainPic {
  position: relative;
  /* border-radius: 20px; */
  /* box-shadow: 0 0 10px black; */
  top: -5%;
  /* margin: auto; */
  height: 70vh;
  width: 60vw;
  /* border: 1px solid white; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-between;
  align-items: flex-end;
}

.arrowRight {
  /* background: green; */
  position: relative;
  left: 0;
  height: 200px;
  width: 100px;
  transition: all 0.3s linear;
}
.arrowRight:hover {
  animation-name: rightArrow;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
i {
  color: rgba(255, 255, 255, 0.527);
  /* box-shadow: 0 0 10px black; */
}

.arrowLeft {
  /* background: green; */
  position: relative;
  left: 0;
  height: 200px;
  width: 100px;
  transition: all 0.3s linear;
}

.arrowLeft:hover {
  animation-name: leftArrow;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.description {
  position: absolute;
  left: 30px;
  top: -100px;
  background: rgba(0, 0, 0, 0.267);
  padding: 40px;
  padding-top: 30px;
  position: relative;
  color: rgb(211, 211, 211);
  text-align: left;
  width: calc(40vw - 40px);
  font-size: 19px;
  /* height: 300px; */
  max-height: 400px;
}
@media (max-width: 1100px) {
  .description {
    font-size: 18px;
  }
}
.position {
  width: 100%;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
}

@keyframes leftArrow {
  0% {
    left: 0;
  }
  10% {
    left: -2px;
  }
  20% {
    left: -4px;
  }
  30% {
    left: -6px;
  }
  40% {
    left: -8px;
  }
  50% {
    left: -10px;
  }
  60% {
    left: -8px;
  }
  70% {
    left: -6px;
  }
  80% {
    left: -4px;
  }
  90% {
    left: -2px;
  }
  100% {
    left: 0;
  }
}
@keyframes rightArrow {
  0% {
    left: 0;
  }
  10% {
    left: 2px;
  }
  20% {
    left: 4px;
  }
  30% {
    left: 6px;
  }
  40% {
    left: 8px;
  }
  50% {
    left: 10px;
  }
  60% {
    left: 8px;
  }
  70% {
    left: 6px;
  }
  80% {
    left: 4px;
  }
  90% {
    left: 2px;
  }
  100% {
    left: 0;
  }
}
@media (max-width: 1000px) {
  .howWeBuild {
    width: 100vw;
    /* margin-left: 0; */
    margin: auto;
    margin-bottom: 10vh;
    animation-name: none;
    transform: translateX(0);
  }
  .mainPic {
    width: 80vw;
    margin: auto;
    height: 60vh;
  }
  .description {
    position: relative;
    /* top: -50px; */
    left: 0;
    top: 0;
    padding: 10px;
    padding-top: 20px;
    width: calc(80vw - 20px);
    margin: auto;
    font-size: 19px;
    /* height: 200pxx; */
  }
  .position {
    flex-direction: column;
    /* border: 1px solid red; */
  }
}

@media (max-width: 600px) {
  .title {
    /* font-size: 25px; */
    top: -5px;
  }
  .howWeBuild {
    margin-bottom: 100px;
  }
  .description {
    /* top: -90px; */
    padding: 10px;
    /* padding-top: 20px; */
    width: calc(100vw - 20px);
    font-size: 13px;
    left: 0;
    margin: auto;
  }
  .mainPic {
    width: 100vw;
  }
}
