.navbar {
  /* position: fixed; */
  z-index: 5000;

  width: 100%;
  height: 100px;
  background: rgb(26, 26, 26);
}

.name {
  margin-left: 5%;
  height: 100px;
  color: rgb(211, 211, 211);
  background: url("../../../assets//logo/logo3.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
}
.nav {
  position: relative;
  top: -100px;
  left: 40vw;
  background: gree/n;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 45%;
  height: 100px;
}

.btn {
  font-size: 19px;
  color: white;
  margin: 0 10px;
  transition: all 0.2s linear;
}

.btn:hover {
  color: rgb(211, 147, 28);
  cursor: pointer;
}

.phone {
  position: relative;
  top: -200px;
  left: 85%;
  height: 100px;
  width: 15%;
  /* background: red; */
}
.phoneMobile {
  display: none;
  position: absolute;
  right: 100px;
  top: 0;
  color: white;
  font-size: 19px;
}
.phoneMobile i {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  color: white;
  outline: none;
  text-decoration: none;
  transition: all 0.2s linear;
}
a {
  text-decoration: none;
}
.phone a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  color: white;
  outline: none;
  text-decoration: none;
  transition: all 0.2s linear;
}

.phone a:hover {
  color: rgb(211, 147, 28);
  cursor: pointer;
}
@media (max-width: 1230px) {
  .btn {
    font-size: 15px;
  }
}
@media (max-width: 1099px) {
  .navbar {
    position: fixed;
    z-index: 5000;
  }
  .phoneMobile {
    display: block;
  }
  .phone {
    display: none;
  }
  .nav {
    display: none;
  }
}

@media (max-width: 700px) {
  .name {
    width: 60%;
  }
  .phone {
    display: none;
  }
  .phoneMobile {
    display: block;
    right: 80px;
  }
}
