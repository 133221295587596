.callRequestForm {
  position: relative;
  /* top: 100vh; */
  /* left: 20px; */
  /* bottom: 150px; */
  /* top: 50%; */

  /* top: calc(100vh - 100px); */
  top: -100px;
  height: 200px;
  width: 70%;
  margin-left: 15%;
  /* margin-bottom: 100px; */
  background: rgba(90, 90, 90, 0.822);
  /* transition: all 0.3s linear; */
  z-index: 100;
}

.form {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  top: 65%;
  width: 100%;
  /* transition: all 0.3s linear; */
}

.title {
  margin-top: 1%;
  position: absolute;
  top: 5%;
  left: 5%;
  font-family: "Lora", serif;
  color: white;
  font-size: 25px;
  /* transition: all 0.3s linear; */
}
.textInfo {
  margin-top: 1%;
  color: white;
  position: absolute;
  font-family: "Lora", serif;

  right: 10%;
  font-size: 18px;
  top: 5%;
  /* transition: all 0.3s linear; */
}
.callRequestName {
  font-family: "Roboto Mono", monospace;
  outline: none;
  font-size: 15px;
  height: 30px;
  width: 200px;
  border: none;
  padding: 5px;
  padding-left: 15px;
  margin: 10px;
  /* transition: all 0.3s linear; */
}
.callRequestPhone {
  font-family: "Roboto Mono", monospace;

  font-size: 15px;
  outline: none;
  height: 30px;
  width: 200px;

  border: none;
  padding: 5px;
  padding-left: 15px;

  margin: 10px;
  /* transition: all 0.3s linear; */
}
.callRequestTime {
  font-family: "Roboto Mono", monospace;

  padding-left: 10px;
  padding-right: 10px;
  font-size: 15px;
  outline: none;
  height: 40px;
  border: none;
  width: 220px;
  background: white;
  margin: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  /* transition: all 0.3s linear; */
}
.callRequestBtn {
  font-family: "Lora", serif;

  outline: none;
  height: 40px;
  width: 220px;

  color: white;
  border: 1px solid white;
  font-size: 15px;
  background: rgb(98, 98, 98);
  margin: 10px;
  /* transition: all 0.3s linear; */
}
.callRequestBtn:hover {
  background: rgb(255, 255, 255);
  color: black;
}
@media (max-width: 1300px) {
  .callRequestForm {
    height: 200px;
  }
  .textInfo {
    top: 25%;
  }
}

@media (max-width: 1110px) {
  .callRequestName,
  .callRequestPhone {
    width: 150px;
  }
  .callRequestTime {
    width: 170px;
  }
  .callRequestBtn {
    width: 170px;
  }
}
@media (max-width: 990px) {
  .callRequestName,
  .callRequestPhone {
    width: 100px;
    font-size: 12px;
  }
  .callRequestTime {
    width: 120px;
    font-size: 12px;
  }
  .callRequestBtn {
    width: 120px;
    font-size: 12px;
  }
}
@media (max-width: 800px) {
  .callRequestForm {
    position: relative;
    height: auto;
    width: 100%;
    margin: 0;
    top: 0;
    background: rgb(90, 90, 90);

    /* top: 50vh; */
  }
  .form {
    flex-direction: column;
    position: relative;
    top: 0;
    margin: 0;
  }
  .title {
    position: relative;
    width: 90%;
    margin: auto;
    left: 0;
    text-align: left;
  }
  .textInfo {
    position: relative;
    width: 90%;
    margin: 35px auto;
    left: 0;
    top: 0;
    text-align: right;
  }

  .callRequestName,
  .callRequestPhone {
    width: 90%;
    margin: 15px 0;
  }
  .callRequestTime {
    width: calc(90% + 20px);
    margin: 15px 0;
  }
  .callRequestBtn {
    width: calc(90% + 20px);
    margin: 15px 0;
  }
}
@media (max-width: 668px) {
  .callRequestForm {
    margin-top: 100px;
  }
}

@media (max-width: 390px) {
  .title {
    font-size: 10px;
    padding-top: 10px;
    /* margin-top: 10px; */
    font-size: 14px;
  }
  .textInfo {
    font-size: 12px;
  }
}

input[type="text"],
input[type="number"],
select:focus {
  font-size: inherit;
}

/* select {
  font-size: inherit;
  width: auto;
} */
/* select:focus {
  font-size: inherit;
} */
